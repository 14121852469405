// src/components/MouseParticlesComponent.js
import React from 'react';
import MouseParticles from 'react-mouse-particles';

const MouseParticlesComponent = () => {
  return (
    <>
      <MouseParticles
        g={2} // gravity
        num={0.5} // number of particles
        color="var(--primary-color)" //color
        cull="a, button" // only trigger particles on anchors and buttons
        level={0.5} // level of the effect (intensity)
      />
    </>
  );
};

export default MouseParticlesComponent;
