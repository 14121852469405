import React from 'react';
import styled from 'styled-components';

const ContactSection = styled.section`
  padding: 100px 20px;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 40px;
  text-align: center;
`;

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  p {
    color: var(--secondary-text-color);
    margin-bottom: 40px;
  }
`;

const EmailLink = styled.a`
  display: inline-block;
  background-color: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 15px 25px;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-size: 1rem;

  &:hover {
    background-color: var(--primary-color);
    color: var(--bg-color);
  }
`;

const Contact = () => {
  return (
    <ContactSection id="contact">
      <Heading>Get In Touch</Heading>
      <Content>
        <p>
          {/* Contact message */}
          I'm currently looking for new opportunities. My inbox is always open.
        </p>
        <EmailLink href="mailto:sofya.klyuchereva@gmail.com">Say Hello</EmailLink>
      </Content>
    </ContactSection>
  );
};

export default Contact;
