import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Proggy';
    src: url('/assets/ProggyClean.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  } 
  /* Import KobeRegular font */
  @font-face {
    font-family: 'Kobe';
    src: url('/assets/Kobe.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  /* CSS Reset */
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0; 
  }

  /* Variables */
  :root {
    --bg-color: #0a192f;
    --primary-color: #64ffda;
    --text-color: #ccd6f6;
    --secondary-text-color: #8892b0;
    --highlight-color: #da81ee; /* New highlight color for About section */
    --font-family: 'Proggy', monospace;
    --small-text-font: 'Tahoma', sans-serif; /* Add small-text font */
    --about-text-color: #b6c5f2;
  }

  /* Global Styles */
  body {
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: var(--font-family);
    line-height: 1.6;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  main {
    /* To account for fixed navbar height */
    padding-top: 80px;
  }

  .scroll-progress {
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    background-color: var(--primary-color); /* Customize color */
    z-index: 1000;
    transition: width 0.25s ease-out;
  }

  /* Utility class to change text color to highlight color */
  .primary-color-text {
    color: var(--highlight-color); /* Set new highlight color #8354e8 */
    font-weight: normal; /* Make highlighted text less bold */
  }

  .highlight {
    color: #FFFFFF;
  }

  .slick-slide img {
    display: block;
    margin: 0 auto;
    border-radius: 10px;
  }
  
  .slick-prev:before,
  .slick-next:before {
    color: black; /* Change the color of navigation arrows */
  }
  
  .slick-dots li button:before {
    color: gray; /* Dots color */
  }
  
  .slick-dots li.slick-active button:before {
    color: #64ffda; /* Active dot color (matches the primary color) */
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Proggy', monospace;
  }

  p, small, span, .small-text {
    font-family: var(--small-text-font);
    font-size: 1rem; /* Smaller text size */
  }
`;
