import React from 'react';
import styled from 'styled-components';
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #112240;
  padding: 20px;
  text-align: center;
`;

const SocialLinks = styled.div`
  margin-bottom: 10px;

  a {
    color: var(--secondary-text-color);
    margin: 0 10px;
    font-size: 1.5rem;

    &:hover {
      color: var(--primary-color);
    }
  }
`;

const CopyRight = styled.p`
  color: var(--secondary-text-color);
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <SocialLinks>
        <a href="https://github.com/sofyaklyuchereva" target="_blank" rel="noopener noreferrer">
          <FaGithub />
        </a>
        <a href="https://www.linkedin.com/in/sofya-klyuchereva-990643236/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
      </SocialLinks>
      <CopyRight>&copy; {new Date().getFullYear()} Sofya Klyuchereva. All rights reserved.</CopyRight>
    </FooterContainer>
  );
};

export default Footer;
