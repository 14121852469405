import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

const ProjectsSection = styled.section`
position: relative;
  padding: 100px 20px;
  background-color: var(--bg-color);
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 40px;
  text-align: center;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  justify-content: center; 
  align-items: stretch; /* Ensures all cards have the same height */
  margin: 0 auto;
  max-width: 800px;
`;

const ProjectCard = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  transition: background-color 0.3s ease;
  padding: 20px;
  border-radius: 8px;
  color: var(--text-color);

  h3 {
    margin-bottom: 15px;
  }

  p {
    color: var(--secondary-text-color);
    margin-bottom: 5px;
  }

  a, button {
    color: var(--primary-color);
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
  }

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }
`;

const ItemTag = styled.span`
  display: inline-block;
  background-color: #b867db;
  color: white;
  padding: 3px 10px; /* Adjust the padding to make them more compact */
  border-radius: 20px;
  margin: 5px 5px 5px 0;
  font-size: 0.8rem; /* Slightly smaller font size */
  white-space: nowrap; /* Ensure tags stay on one line */
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7e44b8; /* Darker purple on hover */
  }
`;


const ProjectLink = styled.a`
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  margin-right: 20px;
`;


const Projects = () => {
  return (
    <ProjectsSection id="projects">
      <Heading>Projects</Heading>
      <ProjectGrid>
      <ProjectCard bgColor="#112240" hoverColor="#5e2663">
        <h3>Retail Market Analysis</h3>
        <p><span className='highlight'>2024</span></p>
        <ItemTag>Analytics</ItemTag>
          <ItemTag>Tableau</ItemTag>
          <ItemTag>SQL</ItemTag>
          <p>
          This project provides an in-depth analysis of <span style={{ color: '#c2c2c2' }}>historical price trends</span> for retail market assets. The primary focus is on <span style={{ color: '#c2c2c2' }}>descriptive analytics</span> to reveal seasonal patterns, peak periods, and price change trends.
          </p>
          <ProjectLink
            onClick={() => window.open('https://github.com/sofyaklyuchereva/Retail-Market-Price-Analysis', '_blank')}
          >
            View Project
          </ProjectLink>
        </ProjectCard>
        <ProjectCard bgColor="#112240" hoverColor="#153d3d">
          <h3>Anemia Detection Classifier</h3>
          <p><span className='highlight'>2024</span></p>
          <ItemTag>Bioinformatics</ItemTag>
          <ItemTag>Healthcare</ItemTag>
          <ItemTag>Real-World Impact</ItemTag>
          <p>
            This project applies a <span style={{ color: '#c2c2c2' }}>logistic regression model</span> to predict the likelihood of <span style={{ color: '#c2c2c2' }}>anemia</span> in patients. Using both <span style={{ color: '#c2c2c2' }}>image pixel data</span> and <span style={{ color: '#c2c2c2' }}>hemoglobin levels</span>, it aims to provide an accessible solution for early anemia detection in healthcare, illustrating how <span style={{ color: '#c2c2c2' }}>machine learning</span> can drive innovation in healthcare technology.
          </p>
          <ProjectLink
            onClick={() => window.open('https://github.com/sofyaklyuchereva/anemia-detection-logistic-regression', '_blank')}
          >
            View Project
          </ProjectLink>
        </ProjectCard>
        <ProjectCard bgColor="#112240" hoverColor="#381536">
          <h3>Vilnius Analogue & Film Festival</h3>
          <p><span className='highlight'>2023</span></p>
          <ItemTag>Film</ItemTag>
          <ItemTag>Performance</ItemTag>
          <p>
            Participated in the Vilnius Analogue & Film Festival, contributing to visual storytelling through analogue photography. This experience deepened my appreciation for creative collaboration and analog mediums in visual arts.
          </p>
          <ProjectLink
            onClick={() => window.open('https://mikiambrozy.com/spiraling-archive', '_blank')}
          >
            View Project
          </ProjectLink>
          <Link to="gallery" smooth={true} duration={500} >
            <ProjectLink>View Gallery</ProjectLink>
          </Link>
        </ProjectCard>
        <ProjectCard bgColor="#112240" hoverColor="#2b2b1e">
          <h3>Exploring Cultural Identity through Patterns of Change</h3>
          <p><span className='highlight'>2022-2023</span></p>
          <ItemTag>Film</ItemTag>
          <ItemTag>Photography</ItemTag>
          <ItemTag>Design</ItemTag>
          <p>
            I created a portfolio which documented my journey in a creative project which explored the concept of cultural identity through a lens of systematic adaptation.
          </p>
          <ProjectLink href="https://drive.google.com/file/d/1z_tjNAhDG4aGaZTzHo2FHOHsADdbq5XA/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            View PDF
            </ProjectLink>
      </ProjectCard>
      </ProjectGrid>
    </ProjectsSection>
  );
};

export default Projects;
