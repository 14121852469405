import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import { FiMenu, FiX } from 'react-icons/fi';
import LogoImage from '../assets/Drawing1.png'; // Replace with your logo path

// Main Navbar styles
const Nav = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background: ${({ isScrolled }) => (isScrolled ? '#0e2444' : 'transparent')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isSmallScreen, isScrolled }) =>
    isSmallScreen && !isScrolled ? '20px 10px' : '10px 10px'};
  z-index: 1000;
  transition: background 0.5s ease-in-out, padding 0.3s ease-in-out;

  @media (min-width: 768px) {
    ${({ isScrolled }) =>
      isScrolled &&
      `
      &::after {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        right: 0;
        height: 15px;
        background: linear-gradient(to bottom, rgba(14, 36, 68, 1), rgba(14, 36, 68, 0));
        pointer-events: none;
      }
    `}
  }

  @media (max-width: 768px) {
    background: transparent !important;
    &::after {
      display: none;
    }
  }
`;

// Logo styling for the left side of the navbar
const Logo = styled.a`
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-left: 20px;
  margin-top: 10px;

  img {
    max-width: 100px;
    height: auto;
    border-radius: 2px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

// Menu styling for the right side
const Menu = styled.ul`
  display: flex;
  list-style: none;
  margin-right: 20px;

  li {
    margin-left: 20px;
    color: var(--text-color);

    a {
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    background: var(--bg-color);
    position: fixed;
    top: 0;
    right: ${({ open }) => (open ? '0' : '-100%')};
    width: 100vw;
    height: 100vh;
    padding-top: 80px;
    transition: right 0.3s;

    li {
      margin: 20px 0;
      text-align: center;
      font-size: 1.5rem;
    }
  }
`;

const MenuToggle = styled.div`
  color: var(--primary-color);
  font-size: 2rem;
  margin-right: 20px;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Nav isScrolled={isScrolled} isSmallScreen={isSmallScreen}>
      {!isSmallScreen && (
        <Logo href="/">
          <img src={LogoImage} alt="Logo" />
        </Logo>
      )}

      <MenuToggle onClick={() => setOpen(!open)}>
        {open ? <FiX /> : <FiMenu />}
      </MenuToggle>

      <Menu open={open}>
        <li>
          <Link to="about" smooth={true} duration={500} onClick={() => setOpen(false)}>
            about
          </Link>
        </li>
        <li>
          <Link to="experience" smooth={true} duration={500} onClick={() => setOpen(false)}>
            experience
          </Link>
        </li>
        <li>
          <Link to="projects" smooth={true} duration={500} onClick={() => setOpen(false)}>
            projects
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500} onClick={() => setOpen(false)}>
            contact
          </Link>
        </li>
        <li>
          <Link to="gallery" smooth={true} duration={500} onClick={() => setOpen(false)}>
            gallery
          </Link>
        </li>
      </Menu>
    </Nav>
  );
};

export default Navbar;
