import React, { useState } from 'react';
import styled from 'styled-components';
import Film1 from '../assets/Film1.jpg'; 
import Film2 from '../assets/Film3.jpg'; 
import Film3 from '../assets/Film2.jpg';
import Camp1 from "../assets/CapmWeek.JPG";
import Camp2 from "../assets/CampWeek1.JPG";

const GallerySection = styled.section`
  padding: 100px 20px;
  background-color: var(--bg-color);
  text-align: center;
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const GalleryImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.5s ease, visibility 0.5s;
`;

const FullScreenImage = styled.img`
  max-width: 90%;
  max-height: 70%;
  object-fit: contain;
  border-radius: 10px;
`;

const Caption = styled.p`
  font-size: 0.8rem;
  color: var(--secondary-text-color);
  margin-top: 10px;
`;

const NavigationButton = styled.button`
  padding: 10px 20px;
  background-color: var(--secondary-text-color);
  color: var(--bg-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 15px;

  &:hover {
    background-color:#6355b5;
  }
`;

const Gallery = () => {
  const images = [
    { src: Camp1, alt: "Campaign Week 1", caption: "Winning Student Board Campaign Week @ École Polytechnique" },
    { src: Camp2, alt: "Campaign Week 2", caption: "Student Board Campaign Week team @ École Polytechnique " },
    { src: Film1, alt: "Vilnius Festival 1", caption: "Spiraling Archive. 2023. performance documentation by Bon Alog (c)" },
    { src: Film2, alt: "Vilnius Festival 2", caption: "Spiraling Archive. 2023. performance documentation by Bon Alog (c)" },
    { src: Film3, alt: "Vilnius Festival 3", caption: "Spiraling Archive. 2023. performance documentation by Bon Alog (c)" }
    ,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const openFullScreen = (index) => {
    setCurrentImageIndex(index);
  };

  const closeFullScreen = () => {
    setCurrentImageIndex(null);
  };

  const showPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <GallerySection id="gallery">
      <h2>Gallery</h2>
      <GalleryGrid>
        {images.map((image, index) => (
          <GalleryImage
            key={index}
            src={image.src}
            alt={image.alt}
            onClick={() => openFullScreen(index)}
          />
        ))}
      </GalleryGrid>

      {currentImageIndex !== null && (
        <FullScreenContainer isOpen={true} onClick={closeFullScreen}>
          <FullScreenImage
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
          />
          <Caption>{images[currentImageIndex].caption}</Caption>
          <div>
            <NavigationButton onClick={(e) => { e.stopPropagation(); showPreviousImage(); }}>
              Previous
            </NavigationButton>
            <NavigationButton onClick={(e) => { e.stopPropagation(); showNextImage(); }}>
              Next
            </NavigationButton>
          </div>
        </FullScreenContainer>
      )}
    </GallerySection>
  );
};

export default Gallery;
