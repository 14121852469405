import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  padding: 100px 20px;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-bottom: 40px;
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 60px;
  text-align: center;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  list-style: none;
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #acb5cc;
    margin-bottom: 30px;
  }

  .primary-color-text {
    color: var(--primary-color);
    font-weight: bold;
  }

  .highlight-color-text {
    color: var(--highlight-color);
    font-weight: 580;
  }
`;


const About = () => {
  return (
    <AboutSection id="about">
      <Heading>About Me</Heading>
      <Content>
        <p>
          Hi, I'm Sofya (Sonya to friends and family) – a second-year university student with a passion for <span className="highlight-color-text">problem-solving</span> and <span className="highlight-color-textt">creativity</span>. I'm pursuing a Bachelor's degree in <span className="highlight-color-text">Mathematics</span> and <span className="highlight-color-text">Computer Science</span>, with a minor in Biology. Lately, I've become fascinated by how technology can drive innovation in both <span className="highlight-color-text">education</span> and <span className="highlight-color-text">business</span>.
        </p>
        
        <p>
          My dream is to create a product that makes education more <span className="highlight-color-text">accessible</span> and <span className="highlight-color-text">engaging</span> for everyone, especially those who lack easy access to it. At the same time, I’m eager to explore how IT solutions can improve business processes through automation, data-driven insights, and user-focused digital design.
        </p>

        <p>
          Outside of academics, I enjoy <span className="highlight-color-text">bouldering</span> and <span className="highlight-color-text">reading</span>. I also have a passion for <span className="highlight-color-text">digital design</span>, particularly <span className="highlight-color-text">poster creation</span> and <span className="highlight-color-text">video editing</span>. I love finding new ways to combine my creative side with my technical interests.
        </p>
      </Content>
    </AboutSection>
  );
};

export default About;