import React from 'react';
import styled from 'styled-components';

const StickyNote = styled.div`
  position: absolute;
  top: 100px; /* Adjust the top position */
  right: 100px;
  background-color: #fdfd96; /* Sticky note color */
  padding: 20px;
  width: 300px; /* Fixed width */
  font-size: 1rem;
  color: black;
  box-shadow: 8px 12px 15px rgba(0, 0, 0, 0.5); /* Larger shadow for depth */
  transform-origin: top right; /* Flip effect from top-right */
  z-index: 10; /* Lower z-index to stay below the navbar */
  font-family: 'Shadows Into Light', cursive; /* Handwriting font */
  background-image: url('/path-to-your-paper-texture.jpg'); /* Paper texture */
  background-size: cover;
  border-radius: 0; /* Sharp edges */
  
  /* Floating effect */
  animation: flipIn 1.5s ease-out forwards, fadeIn 0.6s forwards ease-out, move 3s infinite ease-in-out;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  @keyframes flipIn {
    0% {
      transform: rotateX(-90deg) rotateZ(0deg); /* Start flipped */
      opacity: 0;
    }
    60% {
      transform: rotateX(30deg) rotateZ(0deg); /* Halfway */
      opacity: 1;
    }
    100% {
      transform: rotateX(0deg) rotateZ(-5deg); /* Final position */
    }
  }

  @keyframes move {
    0%, 100% {
      transform: translateY(0) rotateZ(-5deg);
    }
    50% {
      transform: translateY(-10px) rotateZ(-5deg);
    }
  }
  @media (max-width: 1300px) {
    width: 200px; /* Smaller width on phones */
    top: 50px; /* Slightly higher */
    right: 45px; /* Adjusted right position */
    font-size: 0.8rem; /* Smaller font */
    padding: 10px; /* Adjust padding */
    z-index: 3; /* Ensure it's above project grid */
    transform: none; /* Remove rotation on small screens */
  }
  @media (max-width: 1200px) {
    width: 160px; /* Smaller width on phones */
    top: 50px; /* Slightly higher */
    right: 45px; /* Adjusted right position */
    font-size: 0.8rem; /* Smaller font */
    padding: 10px; /* Adjust padding */
    z-index: 3; /* Ensure it's above project grid */
    transform: none; /* Remove rotation on small screens */
  }
  @media (max-width: 1000px) {
    width: 150px; /* Smaller width on phones */
    top: 50px; /* Slightly higher */
    right: 45px; /* Adjusted right position */
    font-size: 0.8rem; /* Smaller font */
    padding: 10px; /* Adjust padding */
    z-index: 3; /* Ensure it's above project grid */
    transform: none; /* Remove rotation on small screens */
  }
  @media (max-width: 700px) {
    width: 120px; /* Smaller width on phones */
    top: 50px; /* Slightly higher */
    right: 45px; /* Adjusted right position */
    font-size: 0.8rem; /* Smaller font */
    padding: 10px; /* Adjust padding */
    z-index: 3; /* Ensure it's above project grid */
    transform: none; /* Remove rotation on small screens */
  }

  /* Responsive styles */
  @media (max-width: 500px) {
    width: 100px; /* Smaller width on phones */
    top: 50px; /* Slightly higher */
    right: 45px; /* Adjusted right position */
    font-size: 0.8rem; /* Smaller font */
    padding: 10px; /* Adjust padding */
    z-index: 3; /* Ensure it's above project grid */
    transform: none; /* Remove rotation on small screens */
  }
`;

const StickyText = styled.p`
  margin-top: 5px;
  font-family: 'Shadows Into Light', cursive; /* Handwriting font */
`;

const InTheWorks = () => {
  return (
    <StickyNote>
      <h3>In the Works...</h3>
      <StickyText>* Clothing brand website</StickyText>
      <StickyText>* AI powered tutor</StickyText>
    </StickyNote>
  );
};

export default InTheWorks;
