import React, { useState } from 'react';
import styled from 'styled-components';
import CertificationImage from '../assets/Certification.jpg';

const ExperienceSection = styled.section`
  padding: 100px 20px;
  transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: var(--text-color);
  margin-bottom: 40px;
  text-align: center;
`;

const ExperienceList = styled.ul`
  max-width: 800px;
  margin: 0 auto;
  list-style: none;
`;

const ExperienceItem = styled.li`
  background-color: #112240;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1c2e4a; /* Slightly lighter background color on hover */
  }

  h3 {
    color: var(--primary-color);
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    color: var(--secondary-text-color);
    margin-bottom: 10px;
  }
`;

const TagContainer = styled.div`
  margin-bottom: 15px; /* Space between tags and description */
`;

const MathTag = styled.span`
  display: inline-block;
  background-color: #b867db; 
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px 5px 5px 0;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #7e44b8; /* Darker purple on hover */
  }
`;

const CSTag = styled.span`
  display: inline-block;
  background-color: #8354e8; 
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px 5px 5px 0;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #5527b8; /* Darker purple on hover */
  }
`;

const BioTag = styled.span`
  display: inline-block;
  background-color: #47bade; 
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 5px 5px 5px 0;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2796b8; /* Darker blue on hover */
  }
`;

const Highlight = styled.span`
  color: #c2c2c2;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 80vw;  /* 80% of the viewport width */
  max-height: 80vh; /* 80% of the viewport height */
  background-color: #fff;
  border-radius: 10px;
  padding: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: auto; /* Enable scrolling if the content is too large */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: red;
  }
`;

const Modal = ({ imageSrc, onClose }) => (
  <ModalOverlay onClick={onClose}>
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <CloseButton onClick={onClose}>×</CloseButton>
      <img src={imageSrc} alt="Popup" style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </ModalContent>
  </ModalOverlay>
);

const Experience = () => {
  const [activeTags, setActiveTags] = useState({}); // State for tracking active tags for each experience item
  const [showModal, setShowModal] = useState(false); // State for controlling the modal
  const [modalImageSrc, setModalImageSrc] = useState(''); // State for modal image

  // Unified function to handle any image click
  const handleImageClick = (imageSrc) => {
    setModalImageSrc(imageSrc); // Set the image source dynamically
    setShowModal(true); // Show the modal
  };

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
    setModalImageSrc('');
  };

  const handleTagClick = (experienceId, tag) => {
    setActiveTags((prevState) => ({
      ...prevState,
      [experienceId]: tag,
    }));
  };

  // Mapping of tag to content
  const contentMapping = {
    'Linear Algebra': (
      <>
        In Linear Algebra courses, I learned about fundamental algebraic structures such as <Highlight>groups, rings, fields, and vector spaces</Highlight>, as well as <Highlight>polynomials, matrix decompositions, and endomorphisms.</Highlight> I also studied linear <Highlight>maps, duality, and the geometry of Euclidean and Hermitian spaces</Highlight>, with a focus on important transformations like normal and unitary maps.
      </>
    ),
    'Functional and Real Analysis': (
      <>
        In these courses, I learned foundational and advanced concepts in mathematical analysis and topology. This includes <Highlight>limits, continuity, derivatives</Highlight>, and the <Highlight>topology of normed vector spaces</Highlight>. I also explored series of functions, <Highlight>Fourier series</Highlight>, and differential equations, deepening my understanding of function approximation and multivariable calculus.
      </>
    ),
    'Probability and Statistics': (
      <>
        In these courses, I learned fundamental concepts of <Highlight>discrete mathematics</Highlight>, including number theory, group theory, graph theory, and Markov chains, as well as key principles of probability and statistics.
      </>
    ),
    'Algorithms': (
      <>
        In this course, I gained a deeper understanding of fundamental algorithms, learning how they work and how to implement them efficiently.
      </>
    ),
    'C++ Programming': (
      <>
        In this course, I was introduced to the C++ programming language and the object-based approach to software design.
      </>
    ),
    'Python Programming': (
      <>
        I used Python extensively for building <Highlight>algorithms, web scraping, and developing machine learning models.</Highlight>
      </>
    ), 
    'Theoretical CS': (
      <>
        In these courses, I learned key concepts in <Highlight>automata theory, formal languages,</Highlight> and <Highlight>Turing machines</Highlight>.
      </>
    ),
    'Bio': (
      <>
        In <Highlight>Cell Biology</Highlight>, I explored how human cells communicate, move, and organize. I learned how to use software such as <Highlight>ChimeraX</Highlight>, <Highlight>CCP4 & Coot</Highlight> and <Highlight>Sparky</Highlight>.
      </>
    ),
    'Networks': (
      <>
        In this course, I learned the architecture and fundamental principles of computer networks, including network programming.
      </>
    ),
    'Alevels': (
      <>
        For my A-levels, I received certification in Computer Science, Mathematics, Physics, and Digital Media & Design. You can find my certificate <Highlight onClick={() => handleImageClick(CertificationImage)}>here</Highlight>.
      </>
    ),
    'Responsibilities': (
      <>
        I edited <Highlight>full-length videos</Highlight> from raw footage, ensuring high-quality production aligned with the <Highlight>creator's vision.</Highlight> Also, collaborated with the employer, effectively <Highlight>incorporating feedback</Highlight> to refine content. Finally, I consistently met tight deadlines, with turnaround times as short as <Highlight>24 hours.</Highlight>
      </>
    ),
    'Reflection': (
      <>
        I developed strong <Highlight>communication</Highlight> and <Highlight>project management skills</Highlight> while working remotely. Additionally, I gained valuable experience working with a <Highlight>large online audience</Highlight> and a <Highlight>high-profile content creator.</Highlight>
      </>
    )
  };



  return (
    <ExperienceSection id="experience">
      <Heading>Experience & Education</Heading>
      <ExperienceList>
        <ExperienceItem>
          <h3>École Polytechnique – BSc Mathematics and CS, Bio minor</h3>
          <p><span className='highlight'>Graduation expected: June 2026</span></p>
          
          <TagContainer>
            <MathTag onClick={() => handleTagClick('Polytechnique', 'Linear Algebra')}>Linear Algebra</MathTag>
            <MathTag onClick={() => handleTagClick('Polytechnique','Functional and Real Analysis')}>Analysis</MathTag>
            <MathTag onClick={() => handleTagClick('Polytechnique','Probability and Statistics')}>Proba&Stats</MathTag>
            <CSTag onClick={() => handleTagClick('Polytechnique','Python Programming')}>Python</CSTag>
            <CSTag onClick={() => handleTagClick('Polytechnique','C++ Programming')}>C++</CSTag>
            <CSTag onClick={() => handleTagClick('Polytechnique','Algorithms')}>Algorithms</CSTag>
            <CSTag onClick={() => handleTagClick('Polytechnique','Theoretical CS')}>Theoretical CS</CSTag>
            <CSTag onClick={() => handleTagClick('Polytechnique','Networks')}>Networks</CSTag>
            <BioTag onClick={() => handleTagClick('Polytechnique','Bio')}>Bio</BioTag>
          </TagContainer>
          
          <p>{activeTags['Polytechnique'] ? contentMapping[activeTags['Polytechnique']] : 'Click to see more!'}</p>
        </ExperienceItem>

        <ExperienceItem>
          <h3>Tutoring</h3>
          <p><span className='highlight'>2022 - Present</span></p>
          <p>In my final year of high school, I discovered my <Highlight>passion for teaching</Highlight> while tutoring younger peers. Since then, I have continued tutoring both <Highlight>online and offline</Highlight>, adapting to the demand and my schedule. This experience has strengthened my ability to <Highlight>foster learning in a personalized way</Highlight>. </p>
        </ExperienceItem>

        <ExperienceItem>
          <h3>Professional Video Editor</h3>
          <p><span className='highlight'>2020 - 2021</span></p>
          <p>After completing a competitive selection process, I was able to secure a <span className='highlight'>remote contract for a YouTuber (5+M followers)</span> </p>
          <TagContainer>
            <MathTag onClick={() => handleTagClick('Editor', 'Responsibilities')}>Responsibilities</MathTag>
            <CSTag onClick={() => handleTagClick('Editor', 'Reflection') }>Reflection</CSTag>
          </TagContainer>
          <p>{activeTags['Editor'] ? contentMapping[activeTags['Editor']] : "Click to learn more!"}</p>
        </ExperienceItem>
      </ExperienceList>

      {/* Render the modal if showModal is true */}
      {showModal && (
        <Modal imageSrc={modalImageSrc} onClose={handleCloseModal} />
      )}
    </ExperienceSection>
  );
};

export default Experience;
